@charset "utf-8";

.wrap {
	.top {
		overflow: auto;
		//background-color: rgba(white, .5);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 9999;
		background-color: #fff;
	}

	.navigation {
		//outline: 1px solid;
	}

	.logo {
		width: 100px;
		padding: 10px 5px;
		float: left;
	}

	.menu_switch {
		//box-sizing:border-box;
		color: #000;
		display: block;
		padding: 5px;
		font-size: 20px;
		float: right;
		// position: absolute;
		// top: 0;
		// right: 0;
		padding: 10px;
		text-align: center;
		//width: 56px;
	}

	.nav-box {
		float: none;
		clear: both;
		width: 100%;
		// height: 93px;
		// font-size: 13px;
		//background-color: #fff;
		box-sizing: border-box;

		.toggle,
		[id^=drop] {
			display: none;
		}

		.toggle {
			//有副選單要切換的父選單
			display: block;
			background-color: #f00;
			//padding: 0 20px;
			color: #000;
			line-height: 50px;
			text-decoration: none;
			border: none;
			padding-left: 10px;

			&+a {
				//緊臨.toggle的a隱藏
				display: none;
			}

			&:hover {
				/* background-color: #000000; */
			}
		}

		//讓副選單出現
		[id^=drop]:checked~ul {
			display: block;
		}

		[id^=drop]:checked~.toggle {
			//font-size: 50px
		}

		a {
			display: block;
			text-decoration: none;
		}

		nav {
			margin: 0;
			padding: 0;
			//font-weight: bold;
			text-align: center;

			&:after {
				content: "";
				display: table;
				clear: both;
			}

			.nav_group {
				display: none;

			}

			.menu_pad {
				height: calc(100vh - 45px);
				display: flex;
				justify-content: center;
				align-items: center;

			}

			ul {
				padding: 0;
				margin: 0;
				list-style: none;

				//position: relative;
				&.menu {
					width: 100%;

					//第一層
					&.mobile {
						.toggle:before {
							// content: '+';
							// position: absolute;
							// right: 0px;
							// font-size: 34px;
							// color: #00f;
							// text-align: center;
							// width: 54px;
						}

						[id^=drop]:checked~.toggle:before {
							// content: '-';
						}
					}

					li {
						//border-bottom: 1px solid #fff;
						//text-align: center;

						&>a {
							//第一層選單bgc
							//background-color: #fff;
							color: #000;
							letter-spacing: 1px;
							line-height: 80px;

						}

						a {
							//不再有下一層的選單
							//padding-left: 10px;
							display: block;
						}

						ul {
							// 第二層
							// 二三層選單隱藏
							display: none;

							&.multi-item {
								.toggle {
									background-color: #f00;

								}

								li {
									a {}

									ul {

										//第三層
										li {
											// border: 0;

											a {
												background-color: #eee;
												// color: #000;
												// background-color: #b9cce5;
												// line-height: 50px;
											}

											&:first-child {
												// display: block;
											}
										}
									}
								}

							}

							li {
								a {
									//第二層單層選單bgc
									background-color: #fff;
									color: #000;
									// background-color: darken(#f00, 5%);
								}
							}
						}
					}
				}

				li {

					//	text-align: center;
					&.single {
						position: relative;

						label {
							i {
								margin-left: 3px;
								position: absolute;
								right: 10px;
								top: 14px;
							}
						}

						i {
							margin-left: 3px;
						}

					}
				}


			}
		}
	}


	// nav ul li ul li:hover {
	//    background: #000000;
	// }
	// nav a:hover {
	//    border-bottom: 50px solid #f00;
	// }
	// nav li > a:after { content: ' /'; }
	// nav li > a:only-child:after { content: 'k'; }
	// .toggle + a,
	// .menu {
	//   display: none;
	// }
	//  ul.menu {
	//  display: block;
	// }
	// nav a:hover,
	// nav ul ul ul a {
	//   background-color: #f00;
	// }
	// //有第三層之第二層
	// nav ul li ul li .toggle,
	// nav ul ul a {
	//   background-color: #f00;
	// }

	////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////
	@include breakpoint(767) {
		.top {
			// overflow: auto;
			overflow: initial;
			//background-color: #fff;

			// background-color: #ee5cdd;
			// position: fixed;			
			// top: 0;
			// left: 0;
			// width: 100%;
			// z-index: 9999;
			.container {
				width: 95%;
				max-width: 1280px;
				margin: 0 auto;
				padding: 20px 0;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: space-around;

				.navigation {
					flex: 0 0 calc(100% - 160px);
					//background-color: red;
					font-weight: 600;
					font-size: 18px;
				}
			}
		}

		.logo {
			flex: 0 0 160px;
			//width: 245px;
			padding: 0;
			float: none;
			clear: both;
			// position: absolute;
			// top: 9px;
			// left: 0;
		}

		.menu_switch {
			display: none;
		}


		.nav-box {
			margin: 0px auto 0 auto;
			// font-size: 0.81rem;

			dl,
			dt,
			dd,
			ul,
			ol,
			li {
				padding: 0;
				margin: 0;
			}

			//讓副選單出現
			[id^=drop]:checked~ul {
				// display: flex;
			}

			.toggle,
			[id^=drop] {
				display: none;
			}

			.toggle {
				&+a {
					//緊臨.toggle的a隱藏
					display: block;
				}
			}

			a {
				padding: 0;
				color: #565c69;
			}

			nav {
				width: 100%;
				margin: 0 auto;

				.nav_group {
					display: block;
				}

				.menu_pad {
					height: initial;
					display: block;
					// justify-content: center;
					// align-items: center;

				}

				ul {
					float: right;
					padding: 0;
					margin: 0;
					list-style: none;


					&.menu {
						width: initial;
						//第一層
						z-index: 100;
						//margin: 0 auto;
						//margin: 10px auto 0;

						&>li {
							// transition: 3s;

							&>a {
								border-bottom: 6px solid transparent;
								padding: 40px 0;
							}

							&:hover {
								// transition: 3s;

								&>a {
									transition: .3s;
									color: #c79f62;
									//line-height: 48px;
									padding: 40px 0;
									border-bottom: 6px solid #c79f62;
								}
							}

							&.selected {
								&>a {
									color: #c79f62;
									//line-height: 48px;
									border-bottom: 6px solid transparent;
									cursor: default;
								}

							}
						}

						li {
							//padding: 0 8px;
							margin: 0 8px 0 0;
							//讓副選單不再隨主選單移動;
							position: static;
							border-bottom: 0;

							&:last-child {
								margin: 0;

								&:after {
									content: '';
									padding: 0;
								}
							}

							&:after {
								content: '|';
								display: inline-block;
								padding-left: 8px;
							}

							ul.multi-item li ul li a {
								background: none;

							}

							a {
								// padding: 0;
								display: inline;

							}


							&>a {
								//line-height: 48px;
								background: none;
								//padding-top: 8px;


							}

							ul {

								// 第二層
								li {
									a {
										background: none;
									}
								}
							}

						}

						ul {
							//第二層
							width: 100%;
							display: block;
							background-color: #fff;
							position: absolute;
							top: 58px;
							left: 0px;
							z-index: 100;

							.toggle {
								&+a {
									//緊臨.toggle的a隱藏
									font-size: 1rem;
									font-weight: bold;
								}
							}

							li {}

							&.single-item {
								width: 200px;
								padding: 20px 0;
								border-top: 3px solid #00f;
								top: 63px;
								left: 50%;
								transform: translateX(-50%);

								li {
									display: block;
									text-align: center;

									a {
										line-height: 30px;
										font-size: 13px;
										padding: 10px 0;

										&:hover {
											// text-decoration: underline;
											color: #00f;
										}
									}
								}
							}

							&.multi-item {
								box-sizing: border-box;
								padding: 35px 40px 60px;
								border-top: 1px solid #eee;

								.toggle {
									//background-color: #f00;
									background: none;

								}

								li {
									display: block;
									flex: 0 0 142px;
									padding: 0;


									&>ul {
										//第三層
										padding: 0;
										position: static;
										display: block;

										li {
											position: static;
											clear: both;
											margin: 0;
											line-height: normal;

											a {
												//background: none;
												line-height: 20px;

												&:hover {
													color: #f00;

												}
											}
										}

									}
								}
							}

							li {
								position: relative;
								display: inline-block;
							}
						}
					}

					li {
						margin: 0px;
						display: inline-block;

						//	text-align: center;
						&.single {
							position: relative;

							i {
								font-size: 1rem;
								position: relative;
								top: -2px;
							}


						}

						&.single:hover>ul {
							display: block;
						}

						&.multi {
							&>a {
								cursor: default;
							}
						}

						&.multi:hover>ul {
							display: flex;
						}
					}
				}
			}
		}
	}

	////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////


	@include breakpoint(960) {
		.top {

			// overflow: initial;
			// background-color: #fff;
			.container {
				// width: 95%;
				// max-width: 1280px;
				// margin: 0 auto;
				// padding: 20px 0;
				// position: relative;
				// display: flex;
				// align-items: center;
				// justify-content: space-around;

				.navigation {
					flex: 0 0 calc(100% - 245px);
					// font-weight: 600;
					font-size: 22px;
				}
			}
		}

		.logo {
			flex: 0 0 245px;
			// padding: 0;
			// float: none;
			// clear: both;
		}

		.menu_switch {
			// display: none;
		}


		.nav-box {
			// margin: 0px auto 0 auto;

			dl,
			dt,
			dd,
			ul,
			ol,
			li {
				// padding: 0;
				// margin: 0;
			}

			//讓副選單出現
			[id^=drop]:checked~ul {}

			.toggle,
			[id^=drop] {
				// display: none;
			}

			.toggle {
				&+a {
					//緊臨.toggle的a隱藏
					// display: block;
				}
			}

			a {
				// padding: 0;
				// color: #565c69;
			}

			nav {
				// width: 100%;
				// margin: 0 auto;

				.nav_group {
					// display: block;
				}

				.menu_pad {
					// height: initial;
					// display: block;
				}

				ul {
					// float: right;
					// padding: 0;
					// margin: 0;
					// list-style: none;


					&.menu {
						// width: initial;
						//第一層
						// z-index: 100;

						&>li {

							&>a {
								// border-bottom: 6px solid transparent;
								// padding: 40px 0;
							}

							&:hover {

								&>a {
									// color: #c79f62;
									// padding: 40px 0;
									// border-bottom: 6px solid #c79f62;
								}
							}

							&.selected {
								&>a {
									// color: #c79f62;
									// border-bottom: 3px solid #c79f62;
								}
							}
						}

						li {
							// margin: 0 8px 0 0;
							//讓副選單不再隨主選單移動;
							// position: static;
							// border-bottom: 0;

							&:last-child {
								// margin: 0;

								&:after {
									// content: '';
									// padding: 0;
								}
							}

							&:after {
								// content: '|';
								// display: inline-block;
								// padding-left: 8px;
							}

							ul.multi-item li ul li a {
								// background: none;
							}

							a {
								// display: inline;
							}

							&>a {
								// background: none;
							}

							ul {

								// 第二層
								li {
									a {
										// background: none;
									}
								}
							}
						}

						ul {

							//第二層
							// width: 100%;
							// display: block;
							// background-color: #fff;
							// position: absolute;
							// top: 58px;
							// left: 0px;
							// z-index: 100;
							.toggle {
								&+a {
									//緊臨.toggle的a隱藏
									// font-size: 1rem;
									// font-weight: bold;
								}
							}

							li {}

							&.single-item {
								// width: 200px;
								// padding: 20px 0;
								// border-top: 3px solid #00f;
								// top: 63px;
								// left: 50%;
								// transform: translateX(-50%);

								li {
									// display: block;
									// text-align: center;

									a {
										// line-height: 30px;
										// font-size: 13px;
										// padding: 10px 0;

										&:hover {
											// color: #00f;
										}
									}
								}
							}

							&.multi-item {
								// box-sizing: border-box;
								// padding: 35px 40px 60px;
								// border-top: 1px solid #eee;

								.toggle {
									// background: none;

								}

								li {
									// display: block;
									// flex: 0 0 142px;
									// padding: 0;


									&>ul {
										//第三層
										// padding: 0;
										// position: static;
										// display: block;

										li {
											// position: static;
											// clear: both;
											// margin: 0;
											// line-height: normal;

											a {
												// line-height: 20px;

												&:hover {
													// color: #f00;

												}
											}
										}

									}
								}
							}

							li {
								// position: relative;
								// display: inline-block;
							}
						}
					}

					li {

						// margin: 0px;
						// display: inline-block;
						&.single {
							// position: relative;

							i {
								// font-size: 1rem;
								// position: relative;
								// top: -2px;
							}
						}

						&.single:hover>ul {
							// display: block;
						}

						&.multi {
							&>a {
								// cursor: default;
							}
						}

						&.multi:hover>ul {
							// display: flex;
						}
					}
				}
			}
		}
	}

	////////////////////////////////////////////////////////////

	@include breakpoint(1280) {
		.nav-box {
			margin: 0px auto 0 auto;
			//font-size: 1.25rem;

			nav {
				// width: 100%;
				// margin: 0 auto;

				a {
					// padding: 0;
					// color: #565c69;
				}

				ul {
					// float: right;
					// padding: 0;
					// margin: 0;
					// list-style: none;

					&.menu {
						//第一層
						// z-index: 100;
						// margin: 0 auto;
						// margin: 18px auto 0;

						&>li {

							// padding: 0 8px;
							//讓副選單不再隨主選單移動;
							// position: static;
							// border-bottom: 0;
							&:hover {
								&>a {
									// color: rgb(80, 180, 182);
									// line-height: 72px;
									// padding-bottom: 6px; //padding-bottom: 0px;
									// border-bottom: 3px solid #f00;
								}
							}

							&.selected {

								&>a {
									// color: #c79f62;
									// line-height: 72px;
									// padding-bottom: 6px;
									// border-bottom: 3px solid #c79f62;

								}

							}

							&>a {
								// line-height: 79px;
							}

						}

						ul {
							//第二層
							// width: 100%;
							// display: block;
							// background-color: #fff;
							// position: absolute;
							top: 87px;
							// left: 0px;
							// z-index: 100;

							.toggle {
								&+a {
									//緊臨.toggle的a隱藏
									font-size: 1.15rem;
								}
							}

							&.single-item {
								// padding: 20px 0;
								top: 86px;
								font-size: 21px;

								li {
									// display: block;
									// text-align: center;

									a {
										line-height: 22px;
										font-size: 1rem;

										&:hover {
											// text-decoration: underline;
										}
									}
								}
							}

							&.multi-item {
								// box-sizing: border-box;
								// padding: 35px 40px 60px;

								li {
									// display: block;
									flex: 0 0 160px;
									// padding: 0;

									&>ul {
										//第三層
										// padding: 0;
										// position: static;
										// display: block;

										li {
											// position: static;
											// clear: both;
											// margin: 0;
											// line-height: normal;

											a {
												line-height: 30px;

												&:hover {
													// text-decoration: underline;
												}
											}
										}

									}
								}
							}

							li {
								// position: relative;
								// display: inline-block;
							}
						}
					}

					li {

						// margin: 0px;
						// display: inline-block;
						&:hover>ul {
							// display: flex;
						}
					}


				}
			}


		}

























































	}
}