@charset "utf-8";
@import "assistant.scss";
@import "normalize.scss";
@import "set.scss";
@import "mixin";
@import "normalize";
@import "keyframes";
@import "component.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&family=Rowdies:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@500;600;700&family=Rowdies:wght@700&display=swap');

html,
body {
  font-family: "蘭亭黑", "Noto Sans TC", "微軟正黑體",
    'Arial', serif;
  color: #666;
  font-size: 16px;
  line-height: 1.5;

  a {
    color: #666;
  }
}

.wrap {
  padding-top: 50px;

  //background-color: rgb(209, 170, 170);
  p {
    font-weight: bold;
  }

  .kv {
    //max-width: 1280px;
    margin: 0 auto;

    .kv_img {
      padding-top: 49%;
      background-size: cover;
      background-position: center 0;
    }
  }

  .box {
    max-width: 1280px;
    margin: 0 auto 50px;
    position: relative;

    img {
      width: 100%;
    }

    .main {}
  }

  @include breakpoint(767) {
    padding-top: 120px;

    p {
      //font-size: 22px;
    }

    .kv {}

    .box {
      margin: 0 auto 100px;
    }
  }

  @include breakpoint(960) {
    //background-color: rgb(171, 105, 183);
  }

  @include breakpoint(1280) {
    .kv {
      .kv_img {
        // padding-top: 450px;
      }
    }

    //background-color: rgb(110, 174, 202);
  }
}