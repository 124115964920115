@charset "utf-8";
@import "style.scss";
@import "nav";
@import "footer";
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&family=Rowdies:wght@700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@500;600;700&family=Rowdies:wght@700&display=swap');

html,
body {
  //overflow-y: hidden;
  // font-family: 'Noto Sans TC', sans-serif;
  // font-size: 16px;
}



.wrap {

  .kv {
    max-width: initial;

    .slide_img {
      div {
        padding-top: 66%;
        background-size: cover;
        background-position: center center;
      }
    }
  }

  .box {
    .main {
      .content {
        width: 90%;
        //max-width: 1280px;
        margin: 0 auto;

        //單頁共用
        h1 {
          font-size: 26px;
          color: #000;

          &:after {
            content: '';
            display: inline-block;
            margin: 0 0 2px 5px;
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-right: 0px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 15px solid;
          }
        }


        .group1 {
          section {
            // background-color: $a;
            box-sizing: border-box;
            padding: 20px;
            overflow: hidden;

            p {
              font-size: 1rem;
              margin: 0;
              padding: 0;
              line-height: 1.7em;
            }

            a {
              display: block;
              float: right;
              font-size: 20px;
              text-decoration: none;
              color: #444;
              font-weight: bold;
              margin-top: 20px;
            }

            .index_group1_img {
              padding-top: 71%;
              background-size: cover;
            }
          }

        }

        .group2 {
          @include list-reset();
          padding: 20px;

          section {
            .slider {

              // overflow: hidden;
              .large {
                &>a {
                  display: block;

                  .index_group2_img {
                    padding-top: 66.7%;
                    background-size: cover;
                  }
                }
              }

              .slide_txt {
                // width: 50%;
                // float: right;
                font-size: 13px;

                h2 {
                  font-weight: normal;

                  span {
                    color: #000;
                    font-weight: 600;
                  }
                }

                p {
                  font-weight: normal;
                  line-height: 2;
                  margin: 0;
                }
              }
            }

            .slider_nav_box {
              //width: 50%;
              margin-top: 20px;


              .progress {
                display: block;
                width: 200px;
                height: 3px;
                margin: 20px auto 0;

                border-radius: 10px;
                overflow: hidden;

                background-color: #f5f5f5;
                background-image: linear-gradient(to right, #777, #777);
                background-repeat: no-repeat;
                background-size: 0 100%;

                transition: background-size .4s ease-in-out;

                .sr-only {
                  position: absolute;
                  width: 1px;
                  height: 1px;
                  padding: 0;
                  margin: -1px;
                  overflow: hidden;
                  clip: rect(0, 0, 0, 0);
                  border: 0;
                }
              }

              .slider-nav {
                //background-color: #f00;
                // display: flex;
                // justify-content: flex-end;
                width: 280px;
                margin: 0 auto;

                .small {
                  //flex: 0 0 33.3%;
                  overflow: hidden;
                  box-sizing: border-box;
                  padding: 0 9px;
                  transition: .3s;
                  cursor: pointer;

                  &:nth-child(odd) {}

                  &.small.slick-current {
                    // transition: .3s;
                    opacity: .3;
                  }

                  div {
                    width: 75px;
                    height: 75px;
                    // width: 80%;
                    // padding-top: 80%;
                    background-size: cover;
                    background-position: center center;
                    overflow: hidden;
                    border-radius: 50%;
                    margin: 0 auto;
                    //outline: 1px solid;
                    position: relative;

                    img {
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      top: 0;
                    }
                  }

                }
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(767) {
    .kv {
      max-width: initial;

      .slide_img {
        div {
          padding-top: 46%;
          // background-size: cover;
          // background-position: center center;
        }
      }
    }

    .box {
      //padding-top: 120px;

      .main {
        .content {

          //單頁共用
          h1 {
            font-size: 30px;
            margin: 0px auto 30px;
            // border-top: 1px solid #666;
            // padding-top: 20px;
            // color: #999;

            &:after {
              // content: '';
              // display: inline-block;
              // margin: 0 0 2px 5px;
              // width: 0;
              // height: 0;
              border-top: 10px solid transparent;
              border-right: 0px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 18px solid;
            }
          }

          // width: 90%;
          // margin: 0 auto;
          .group1 {
            display: flex;
            flex-flow: wrap row;
            justify-content: space-between;
            margin-bottom: 100px;

            section {
              // background-color: $a;
              // box-sizing: border-box;
              // padding: 20px;
              padding: 90px 27px 0px 0;

              &:nth-child(1) {
                flex: 0 0 47%;
              }

              &:nth-child(2) {
                flex: 0 0 50%;
              }


              p {
                // margin: 0;
                // padding: 0;
                // line-height: 1.7em;
              }

              a {
                // display: block;
                // float: right;
                font-size: 22px;
                // text-decoration: none;
                // color: #444;
                // font-weight: bold;
                // margin-top: 20px;
                // letter-spacing: -1px;
              }

            }
          }

          .group2 {
            padding: 0;
            @include list-reset();

            section {
              .slider {
                overflow: hidden;

                .slide_txt {
                  width: 56%;
                  float: right;
                  margin-top: 20px;
                }
              }

              .slider_nav_box {
                // width: 300px;
                position: relative;
                margin-top: 0;

                .progress {
                  // display: block;
                  // width: 200px;
                  // height: 3px;
                  // margin: 20px auto 0;
                  margin: 0;
                  position: absolute;
                  bottom: 40px;
                  left: 50px;

                  // border-radius: 10px;
                  // overflow: hidden;

                  // background-color: #f5f5f5;
                  // background-image: linear-gradient(to right, black, black);
                  // background-repeat: no-repeat;
                  // background-size: 0 100%;

                  // transition: background-size .4s ease-in-out;

                  .sr-only {
                    // position: absolute;
                    // width: 1px;
                    // height: 1px;
                    // padding: 0;
                    // margin: -1px;
                    // overflow: hidden;
                    // clip: rect(0, 0, 0, 0);
                    // border: 0;
                  }
                }


                .slider-nav {
                  width: 300px;
                  // width: 100%;
                  position: absolute;
                  bottom: 64px;

                  .small {
                    // overflow: hidden;
                    // box-sizing: border-box;
                    padding: 0 15px 0 0;

                    div {
                      // display: block;
                      width: 85px;
                      height: 85px;
                      // border-radius: 50%;
                      // background-color: #f00;
                      // overflow: hidden;
                      // margin: 0 auto;
                      margin: 0;
                      // display: flex;
                      // justify-content: center;
                      // align-items: center;

                      img {
                        //width: 130px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(960) {
    .box {
      // padding-top: 120px;

      .main {

        .content {

          //單頁共用
          h1 {
            //font-size: 45px;
            // margin: 0px auto 30px;
            // border-top: 1px solid #666;
            // padding-top: 20px;
            // color: #999;
            margin-bottom: 80px;

            &:after {
              // content: '';
              // display: inline-block;
              // margin: 0 0 2px 5px;
              // width: 0;
              // height: 0;
              // border-top: 10px solid transparent;
              // border-right: 0px solid transparent;
              // border-bottom: 10px solid transparent;
              // border-left: 18px solid;
            }
          }



          // width: 90%;
          // margin: 0 auto;
          .group1 {
            // display: flex;
            // flex-flow: wrap row;
            // justify-content: space-between;

            section {
              // background-color: $a;
              // box-sizing: border-box;
              // padding: 20px;
              // padding: 90px 27px 40px 0;

              &:nth-child(1) {
                flex: 0 0 43%;
              }

              &:nth-child(2) {
                // flex: 0 0 50%;
              }


              p {
                // margin: 0;
                // padding: 0;
                // line-height: 1.7em;
              }

              a {
                // display: block;
                // float: right;
                font-size: 25px;
                // text-decoration: none;
                // color: #444;
                // font-weight: bold;
                margin-top: 30px;
                // letter-spacing: -1px;
              }

            }
          }

          .group2 {
            @include list-reset();

            section {
              .slider {
                // overflow: hidden;
                // background-color: $b;

                .slide_txt {
                  width: 60%;

                  // float: right;
                  // margin-top: 20px;
                  h2 {
                    font-size: 25px;

                  }
                }
              }

              .slider_nav_box {

                // width: 300px;
                // position: relative;
                .progress {
                  // display: block;
                  // width: 200px;
                  // height: 3px;
                  // margin: 20px auto 0;
                  // margin: 0;
                  // position: absolute;
                  bottom: 0px;
                  //left: 40px;

                  // border-radius: 10px;
                  // overflow: hidden;

                  // background-color: #f5f5f5;
                  // background-image: linear-gradient(to right, black, black);
                  // background-repeat: no-repeat;
                  // background-size: 0 100%;

                  // transition: background-size .4s ease-in-out;

                  .sr-only {
                    // position: absolute;
                    // width: 1px;
                    // height: 1px;
                    // padding: 0;
                    // margin: -1px;
                    // overflow: hidden;
                    // clip: rect(0, 0, 0, 0);
                    // border: 0;
                  }
                }

                .slider-nav {
                  // width: 300px;
                  // position: absolute;
                  bottom: 25px;
                  // background-color: $a;

                  li {
                    // overflow: hidden;
                    // box-sizing: border-box;
                    // padding: 0;

                    a {
                      // display: block;
                      // width: 80px;
                      // height: 80px;
                      // border-radius: 50%;
                      // background-color: #f00;
                      // overflow: hidden;
                      // margin: 0 auto;
                      // margin: 0;
                      // display: flex;
                      // justify-content: center;
                      // align-items: center;

                      img {
                        // width: 130px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(1280) {}
}