@charset "utf-8";

.wrap {
  .xxx {
    background-color: #ccc;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 0;
    transform: translateX(-50%);
    max-width: 1280px;

    .slide-obj {
      background-color: #dbd6c3;
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      z-index: 9999;

      a {
        color: #fff;
        font-size: 21px;
      }
    }
  }


  footer {
    background-color: #dbd6c3;
    padding: 20px 0;
    color: #595757;


    .p1 {
      width: 95%;
      max-width: 1280px;
      margin: 0 auto 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #fff;

      .logo_ft {
        max-width: 240px;
        margin: 0 0 10px;
      }

      ul {
        margin: 0;
        list-style-type: none;
        padding: 0;
        font-size: 12px;
        width: 265px;

        li {
          // background-image:
          //   linear-gradient(90deg, #000 1px, transparent 0%);
          border-left: 1px solid #000;
          margin-bottom: 5px;
          padding-left: 5px;
        }
      }
    }

    .p2 {
      width: 95%;
      max-width: 1280px;
      margin: 0 auto;
      display: flex;
      flex-flow: wrap column;
      @include list-reset();

      .cr {
        font-size: 12px;
        //text-align: center;
        order: 0;
      }



      ul {
        order: -1;
        // display: flex;
        // flex-flow: wrap row;
        font-size: 20px;
        // width: 90%;
        margin: 0 0 10px;

        li {
          // flex: 0 0 20%;
          float: left;
          margin-right: 10px;

          a {
            text-decoration: none;
            margin: 0 auto;
            width: 40px;
            height: 40px;
            display: block;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: #a38a77;
            transition: .3s;

            &:hover {
              transition: .3s;
              color: #a38a77;
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  ////////////////////////////////////////////////////////////

  @include breakpoint(960) {
    footer {
      // background-color: #dbd6c3;
      padding: 45px 0 75px;

      .p1 {
        // width: 90%;
        // max-width: 1280px;
        margin: 0 auto 10px;
        // padding-bottom: 15px;
        // border-bottom: 1px solid #fff;

        .logo_ft {
          // margin-bottom: 10px;
          max-width: 270px;
          margin: 0 0 10px 0;
        }

        ul {
          // margin: 0 auto;
          // list-style-type: none;
          // padding: 0;
          margin: 0;
          font-size: 16px;
          width: initial;

          li {
            display: inline-block;
            margin-right: 5px;
            letter-spacing: 1px;
            // background-image:
            //   linear-gradient(90deg, #000 1px, transparent 0%);
            // margin-bottom: 10px;
            // padding-left: 10px;

          }
        }
      }

      .p2 {
        // width: 90%;
        // max-width: 1280px;
        // margin: 0 auto;
        // display: flex;
        flex-flow: wrap row;
        justify-content: space-between;
        align-items: center;
        // @include list-reset();

        .cr {
          // font-size: 13px;
          // text-align: center;
          //text-align: left;
          // order: 0;
          flex: 0 0 400px;
          font-size: 16px;
        }



        ul {
          flex: 0 0 300px;
          order: 1;
          display: flex;
          flex-flow: wrap row;
          // font-size: 25px;
          // margin-bottom: 20px;
          margin: 0;
          justify-content: flex-end;

          li {
            flex: 0 0 40px;
            box-sizing: border-box;
            padding-left: 5px;
            float: none;
            margin: 0;

            a {
              // text-decoration: none;
              // margin: 0 auto;
              width: 40px;
              height: 40px;
              // display: block;
              // border-radius: 50%;
              // display: flex;
              // justify-content: center;
              // align-items: center;
              // color: #fff;
              // background-color: #a38a77;
            }
          }
        }
      }
    }
  }

  ////////////////////////////////////////////////////////////

  @include breakpoint(1280) {}
}