@charset "utf-8";
// @import "assistant.scss";
// @import "set.scss";
@import "mixin";

html,
body {
  //background-color: rgb(209, 209, 245);
}

.for-pc {
  display: none;
}


//文字區塊共同設定, 只設定字體大小、間距
.copywriting {}

.wrap {
  h1.page_title {
    font-size: 26px;
    width: 90%;
    max-width: 1280px;
    margin: 50px auto 30px;
    border-bottom: 1px solid;
    padding-bottom: 15px;
    color: #000;

    span {
      font-size: .6em;
      color: #666;

      &:before {
        content: '|';
        display: inline-block;
        margin-right: 5px;

      }
    }
  }

  @include breakpoint(767) {
    .for-pc {
      display: block;
    }

    .for-m {
      display: none;
    }

    h1.page_title {
      // width: 90%;
      // margin: 0 auto;
      // border-bottom: 1px solid;
      // padding-bottom: 15px;
      // color: #000;
      font-size: 30px;
      margin: 90px auto 50px;

      span {
        font-size: 18px;
        // color: #666;

        &:before {
          // content: '|';
          // display: inline-block;
          margin-right: 10px;

        }
      }
    }

  }

  @include breakpoint(960) {
    h1.page_title {
      // width: 90%;
      // margin: 0 auto;
      // border-bottom: 1px solid;
      // padding-bottom: 15px;
      // color: #000;
      //font-size: 45px;
      //margin: 90px auto 60px;

      span {
        // font-size: 18px;
        // color: #666;

        &:before {
          // content: '|';
          // display: inline-block;
          // margin-right: 10px;

        }
      }
    }
  }

  @include breakpoint(1280) {}
}